import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import senPromoIcon from "../../assets/images/icon-sen-promo.png"
import { acceptNotaryPromotionToSen, declineNotaryPromotionToSen } from "helpers/backendHelper";
import { showError } from "helpers/utilHelper";
import Lottie from 'lottie-react';
import promotionToSenAnimation from 'assets/animations/promotion-to-sen.json';
import { useProfile } from "context/profile";
import { useAuth } from "context/auth";

const SenPromotion = () => {

  const { refreshProfile, notary } = useProfile();
  const { isImpersonation } = useAuth();

  const [showAnimation, setShowAnimation] = useState(false);

  const confirmPromotionToSen = () => {
    acceptNotaryPromotionToSen()
      .then(() => {
        setShowAnimation(true);
        refreshProfile();
      })
      .catch(() => {
        showError("Unable to confirm promotion to Super Express Notary!")
      });
  };

  const cancelPromotionToSen = () => {
    declineNotaryPromotionToSen()
      .then(() => {
        refreshProfile();
      })
      .catch(() => {
        showError("Unable to decline promotion to Super Express Notary!")
      });
  };

  return (
    <>
      {
        !!notary && !!notary.isPendingPromotion && !isImpersonation() && !showAnimation && !notary.forceTermsAgreement &&
        <SweetAlert
          title={<div className="text-primary">Congratulations!</div>}
          custom
          showCancel
          showConfirm
          cancelBtnText="Cancel"
          confirmBtnBsStyle="primary"
          confirmBtnCssClass="me-2 button-alert"
          cancelBtnBsStyle="secondary"
          cancelBtnCssClass="ms-2 button-alert"
          btnSize="default"
          confirmBtnText="Confirm"
          customIcon={senPromoIcon}
          onConfirm={() => confirmPromotionToSen()}
          reverseButtons={false}
          onCancel={() => cancelPromotionToSen()}
          style={{ backgroundColor: '#FFFFFF', width: 600, padding: "20px 40px" }}
          closeOnClickOutside={false}
        >
          <p className="text-primary mt-2">You&apos;ve been invited to become a Super Express Notary!</p>
          <p className="text-primary ">By confirming, you&apos;ll gain priority access to high-quality leads. This upgrade is designed to increase your visibility and help you connect with clients who need prompt assistance. Are you ready to take advantage of these benefits? Confirm to join Super Express!</p>

          <p className="text-primary fw-bold text-start mt-5">Please make sure:</p>
          <div className="btn-light d-flex w-100 p-2 d-flex align-items-center">
            <div className="blue-circle me-3">1</div>
            <b>Agree to the Notary T&C</b>
          </div>
          <div className="btn-light d-flex w-100 mt-2 p-2 d-flex align-items-center">
            <di className="blue-circle me-3">2</di>
            <b>Complete the GLBA form</b>
          </div>
          <p className="text-primary text-start mt-5">You can complete both from your Notary Profile.</p>
        </SweetAlert>
      }
      {
        showAnimation &&
        <div className="lottie-container">
          <Lottie
            animationData={promotionToSenAnimation}
            loop={false}
            style={{ width: "100%", height: "auto" }}
            className="lottie-obj"
            onComplete={() => setShowAnimation(false)}
          />
        </div>
      }
    </>
  )
}

export default SenPromotion
