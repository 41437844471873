import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import MetaTitle from "components/Shared/MetaTitle";
import Col from "components/Shared/Col";
import BackButton from "components/Shared/BackButton";
import FormSteps from "components/Shared/GlbaAssessment/Steps";
import FormGeneralInformation from "components/Shared/GlbaAssessment/Form/GeneralInformation";
import { getProfile } from "store/actions";
import { useProfile } from "context/profile";

const GeneralInformation = () => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/
  // next/prev routes used for wizard navigation
  const [navRoutes, setNavRoutes] = useState({ next: null, prev: null });

  const { notary } = useProfile();

  /********** EFFECTS **********/
  // runs once on component mount
  useEffect(() => {
    refreshProfile();
  }, [navRoutes]);

 /********** OTHER **********/
  const refreshProfile = () => dispatch(getProfile());

  return <React.Fragment>
    <div className="page-content">
      <MetaTitle>GLBA Assessment Form - General Information</MetaTitle>
      <Container fluid className='glba-assessment-form'>
        <BackButton />
        <Row>
          <Col>
            <Card>
              <CardHeader className="bg-transparent pt-3 pb-0">
                <Row>
                  <Col>
                    <div className="card-title pt-1 pb-4 mb-0">GLBA Assessment Form</div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <FormSteps currentStep="general" setNavRoutes={setNavRoutes} />
                <FormGeneralInformation defaultValues={notary?.glba} notaryId={notary.id} navRoutes={navRoutes} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

export default GeneralInformation;