import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NotaryGlba from 'model/notaryGlba';
import { nullsToEmptyStrings, showBriefSuccess, showError } from "helpers/utilHelper";
import { useNavigate } from "react-router-dom";
import { route } from "helpers/routeHelper";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Button, Label, Input, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Col from "components/Shared/Col";
import { ValidationException } from "helpers/errorHelper";
import { createGlba, updateGlbaGeneral, doUpdateGlbaGeneralCleanup, doCreateGlbaCleanup } from "store/actions";
import classnames from "classnames";

const FormGeneralInformation = props => {

  const { defaultValues, notaryId, navRoutes } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/
  const [nextRoute, setNextRoute] = useState('');
  const [submitCount, setSubmitCount] = useState(0);

  const isNoChecked = (value) => value === false || value === 0;

  // get redux state from the store
  const { isSaveCreateInProgress, savedCreate, savedCreateError,
    isSaveGeneralInProgress, savedGeneral, savedGeneralError
  } = useSelector(state => state.Profile.GlbaForm);

  /********** FORM CONFIG **********/
  const formInitialValues = {
    isNotaryAuthorizedRepresentative: '',
    authorizedRepresentative: '',
    noOfEmployees: '',
    otherEmployees: '',
    isSecurityTrainingPerformed: '',
    arePhishingSimulationsPerformed: '',
    securityTrainingFrequency: '',
    securityTrainingFrequencyDetails: '',
    ...nullsToEmptyStrings(defaultValues)
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: !!submitCount,
    validateOnBlur: true,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      arePhishingSimulationsPerformed: Yup.mixed().required("Field is required"),
      isSecurityTrainingPerformed: Yup.mixed().required("Field is required"),
      isNotaryAuthorizedRepresentative: Yup.mixed().required("Field is required"),
      authorizedRepresentative: Yup.string()
        .when('isNotaryAuthorizedRepresentative', {
          is: (isNotaryAuthorizedRepresentative) =>
            !isNotaryAuthorizedRepresentative,
          then: Yup.string().required("Field is required"),
          otherwise: Yup.string().nullable(),
        }),
      noOfEmployees: Yup.mixed().required("Field is required"),
      otherEmployees: Yup.string()
        .when('noOfEmployees', {
          is: (noOfEmployees) =>
            +noOfEmployees === NotaryGlba.EMPLOYEES_SELF_AND_OTHERS,
          then: Yup.string().required("Field is required"),
          otherwise: Yup.string().nullable(),
        }),
      securityTrainingFrequency: Yup.mixed().required("Field is required"),
      securityTrainingFrequencyDetails: Yup.string()
        .when('securityTrainingFrequency', {
          is: (securityTrainingFrequency) =>
            +securityTrainingFrequency === NotaryGlba.SECURITY_TRAINING_OTHER,
          then: Yup.string().required("Field is required"),
          otherwise: Yup.string().nullable(),
        }),
    }),
    onSubmit: values => {
      // If glbaId exist we made the update call, otherwise we create the glba form(id)
      if (!!defaultValues?.id) {
        dispatch(updateGlbaGeneral(defaultValues.id, values));
      } else {
        dispatch(createGlba(notaryId, values));
      }
    },
  });

  /********** EFFECTS **********/
  // runs when the call for update the form is made
  useEffect(() => {
    if (savedGeneral === true) {
      handleSaveSuccess();
    } else if (savedGeneral === false) {
      handleSaveFailure();
    }
  }, [savedGeneral]);

  // runs when the call for create the form is made
  useEffect(() => {
    if (savedCreate === true) {
      handleSaveSuccess();
    } else if (savedCreate === false) {
      handleSaveFailure();
    }
  }, [savedCreate]);

  /********** OTHER **********/
  const handleSaveSuccess = () => {

    if (savedCreate) dispatch(doCreateGlbaCleanup());

    if (savedGeneral) dispatch(doUpdateGlbaGeneralCleanup());

    showBriefSuccess('General Information has been saved');

    navigate(route(nextRoute));
  };

  const handleSaveFailure = () => {

    showError('Unable to save general information');

    if (savedCreateError) handleValidationErrors(savedCreateError);
    if (savedGeneralError) handleValidationErrors(savedGeneralError);

    // Enable the save button
    formik.setSubmitting(false);
  };

  const handleValidationErrors = (error) => {
    if (error instanceof ValidationException) {
      for (const [name, message] of Object.entries(error.fields)) {
        formik.setFieldError(name, message);
      }
    }
  };


  /********** EVENT HANDLERS **********/
  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <React.Fragment>
    <Form className="pt-4">
      <div className="card-section">
        <Row>
          <Col lg="6">
            <div className="mb-4">
              <h6 className='mb-2'>Are you an authorized representative of Service Provider with sufficient knowledge to complete this assessment?</h6>
              {/* Option: Self */}
              <div className={classnames({ 'is-invalid': !!formik.errors.isNotaryAuthorizedRepresentative }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="isNotaryAuthorizedRepresentativeSelf"
                  name="isNotaryAuthorizedRepresentative"
                  value={true}
                  checked={Boolean(formik.values.isNotaryAuthorizedRepresentative)}
                  onChange={() => formik.setFieldValue('isNotaryAuthorizedRepresentative', true)}
                />
                <label className="form-check-label" htmlFor="isNotaryAuthorizedRepresentativeSelf">Self</label>
              </div>
              {/* Option: Representative */}
              <div className={classnames({ 'is-invalid': !!formik.errors.isNotaryAuthorizedRepresentative }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="isNotaryAuthorizedRepresentativeRep"
                  name="isNotaryAuthorizedRepresentative"
                  checked={isNoChecked(formik.values.isNotaryAuthorizedRepresentative)}
                  onChange={() => formik.setFieldValue('isNotaryAuthorizedRepresentative', false)}
                />
                <label className="form-check-label" htmlFor="isNotaryAuthorizedRepresentativeRep">Representative</label>
              </div>
              {/* Display Error for isNotaryAuthorizedRepresentative */}
              {!!formik.errors.isNotaryAuthorizedRepresentative && <FormFeedback>{formik.errors.isNotaryAuthorizedRepresentative}</FormFeedback>}

              {/* Conditional Input for Authorized Representative */}
              {isNoChecked(formik.values.isNotaryAuthorizedRepresentative) && (<>
                <Input
                  type="text"
                  className={classnames('form-control mt-3', { 'is-invalid': !!formik.errors.authorizedRepresentative })}
                  name="authorizedRepresentative"
                  placeholder="Title, name, email, phone number"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  invalid={!!formik.errors.authorizedRepresentative}
                  value={formik.values.authorizedRepresentative}
                />
                {!!formik.errors.authorizedRepresentative && (<FormFeedback type="invalid">{formik.errors.authorizedRepresentative}</FormFeedback>)}
              </>
              )}
            </div>

            <div className="mb-4">
              <h6 className='mb-2'>Does Service Provider perform security awareness training?</h6>
              <div className={classnames({ 'is-invalid': !!formik.errors.isSecurityTrainingPerformed }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="yes"
                  name="isSecurityTrainingPerformed"
                  checked={formik.values.isSecurityTrainingPerformed}
                  onChange={() => formik.setFieldValue('isSecurityTrainingPerformed', true)}
                />
                <label className="form-check-label" htmlFor="yes">Yes</label>
              </div>

              <div className={classnames({ 'is-invalid': !!formik.errors.isSecurityTrainingPerformed }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="no"
                  name="isSecurityTrainingPerformed"
                  checked={isNoChecked(formik.values.isSecurityTrainingPerformed)}
                  onChange={() => formik.setFieldValue('isSecurityTrainingPerformed', false)}
                />
                <label className="form-check-label" htmlFor="no">No</label>
              </div>
              {!!formik.errors.isSecurityTrainingPerformed && <FormFeedback type="invalid">{formik.errors.isSecurityTrainingPerformed}</FormFeedback>}
            </div>

            <div className="mb-4">
              <h6 className='mb-2'>Does Service Provider perform phishing simulations?</h6>
              <div className={classnames({ 'is-invalid': !!formik.errors.arePhishingSimulationsPerformed }, 'form-check my-2')} >
                <input
                  className="form-check-input"
                  type="radio"
                  id="yes"
                  name="arePhishingSimulationsPerformed"
                  checked={Boolean(formik.values.arePhishingSimulationsPerformed)}
                  onChange={() => formik.setFieldValue('arePhishingSimulationsPerformed', true)}
                />
                <label className="form-check-label" htmlFor="yes">Yes</label>
              </div>

              <div className={classnames({ 'is-invalid': !!formik.errors.arePhishingSimulationsPerformed }, 'form-check my-2')} >
                <input
                  className="form-check-input"
                  type="radio"
                  id="no"
                  name="arePhishingSimulationsPerformed"
                  checked={isNoChecked(formik.values.arePhishingSimulationsPerformed)}
                  onChange={() => formik.setFieldValue('arePhishingSimulationsPerformed', false)}
                />
                <label className="form-check-label" htmlFor="no">No</label>
              </div>
              {!!formik.errors.arePhishingSimulationsPerformed && <FormFeedback type="invalid">{formik.errors.arePhishingSimulationsPerformed}</FormFeedback>}
            </div>
          </Col>

          <Col lg="6">
            <div className="mb-4">
              <h6 className='mb-2'>How many employees does Service Provider employ?</h6>
              {/* Option: Self */}
              <div className={classnames({ 'is-invalid': !!formik.errors.noOfEmployees }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="self"
                  name="noOfEmployees"
                  value={NotaryGlba.EMPLOYEES_SELF}
                  checked={+formik.values.noOfEmployees === NotaryGlba.EMPLOYEES_SELF}
                  onChange={(e) => formik.setFieldValue('noOfEmployees', e.target.value)}
                />
                <Label className="form-check-label" htmlFor="self">Self</Label>
              </div>

              {/* Option: Self and Others */}
              <div className={classnames({ 'is-invalid': !!formik.errors.noOfEmployees }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="selfAnd"
                  name="noOfEmployees"
                  value={NotaryGlba.EMPLOYEES_SELF_AND_OTHERS}
                  checked={+formik.values.noOfEmployees === NotaryGlba.EMPLOYEES_SELF_AND_OTHERS}
                  onChange={(e) => formik.setFieldValue('noOfEmployees', e.target.value)}
                />
                <Label className="form-check-label" htmlFor="selfAnd">Self and</Label>
              </div>
              {/* Display Error for noOfEmployees */}
              {!!formik.errors.noOfEmployees && <FormFeedback>{formik.errors.noOfEmployees}</FormFeedback>}

              {/* Conditional Input for Other Employees */}
              {+formik.values.noOfEmployees === NotaryGlba.EMPLOYEES_SELF_AND_OTHERS && (<>
                <Input
                  className={classnames('form-control mt-3', { 'is-invalid': !!formik.errors.otherEmployees })}
                  placeholder="Explain"
                  type="text"
                  name="otherEmployees"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.otherEmployees}
                  invalid={!!formik.errors.otherEmployees}
                />
                {!!formik.errors.otherEmployees && (<FormFeedback type="invalid">{formik.errors.otherEmployees}</FormFeedback>)}
              </>
              )}
            </div>

            <div className="mb-4">
              <h6 className='mb-2'>How frequently does Service Provider complete security awareness training?</h6>
              {/* Option: Annually */}
              <div className={classnames({ 'is-invalid': !!formik.errors.securityTrainingFrequency }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="annually"
                  name="securityTrainingFrequency"
                  value={NotaryGlba.SECURITY_TRAINING_ANUALLY}
                  checked={+formik.values.securityTrainingFrequency === NotaryGlba.SECURITY_TRAINING_ANUALLY}
                  onChange={(e) => formik.setFieldValue('securityTrainingFrequency', e.target.value)}
                />
                <Label className="form-check-label" htmlFor="annually">Annually</Label>
              </div>
              {/* Option: Bi-Annually */}
              <div className={classnames({ 'is-invalid': !!formik.errors.securityTrainingFrequency }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="biAnnually"
                  name="securityTrainingFrequency"
                  value={NotaryGlba.SECURITY_TRAINING_BI_ANUALLY}
                  checked={+formik.values.securityTrainingFrequency === NotaryGlba.SECURITY_TRAINING_BI_ANUALLY}
                  onChange={(e) => formik.setFieldValue('securityTrainingFrequency', e.target.value)}
                />
                <label className="form-check-label" htmlFor="biAnnually">Bi-Annually</label>
              </div>
              {/* Option: Other */}
              <div className={classnames({ 'is-invalid': !!formik.errors.securityTrainingFrequency }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="other"
                  name="securityTrainingFrequency"
                  value={NotaryGlba.SECURITY_TRAINING_OTHER}
                  checked={+formik.values.securityTrainingFrequency === NotaryGlba.SECURITY_TRAINING_OTHER}
                  onChange={(e) => formik.setFieldValue('securityTrainingFrequency', e.target.value)}
                />
                <Label className="form-check-label" htmlFor="other">Other</Label>
              </div>
              {/* Display Error for Security Training Frequency */}
              {!!formik.errors.securityTrainingFrequency && <FormFeedback>{formik.errors.securityTrainingFrequency}</FormFeedback>}

              {/* Conditional Input for Security Training Frequency Details */}
              {+formik.values.securityTrainingFrequency === NotaryGlba.SECURITY_TRAINING_OTHER && (<>
                <Input
                  className={classnames('form-control mt-3', { 'is-invalid': !!formik.errors.otherEmployees })}
                  placeholder="Explain"
                  type="text"
                  name="securityTrainingFrequencyDetails"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.securityTrainingFrequencyDetails}
                  invalid={!!formik.errors.securityTrainingFrequencyDetails}
                />
                {formik.errors.securityTrainingFrequencyDetails && (<FormFeedback>{formik.errors.securityTrainingFrequencyDetails}</FormFeedback>)}
              </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-end pt-4">
            <Button type="button" color="primary" className="ms-2" onClick={() => { setNextRoute(navRoutes.next); formik.handleSubmit(); setSubmitCount(prev => ++prev) }} disabled={formik.isSubmitting}>
              {(isSaveGeneralInProgress || isSaveCreateInProgress) && <i className="mdi mdi-spin mdi-loading me-1" />}
              Next
            </Button>
          </Col>
        </Row>
      </div>
    </Form >
  </React.Fragment >
}

FormGeneralInformation.propTypes = {
  notaryId: PropTypes.number,
  defaultValues: PropTypes.object,
  navRoutes: PropTypes.object,
};

export default FormGeneralInformation;