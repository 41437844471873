import React,  { useState, useEffect } from "react";
import { Button, Row, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import Col from "components/Shared/Col";
import { route, routes } from 'helpers/routeHelper';
import { useNavigate } from 'react-router-dom';
import { getNotaryGlba } from "helpers/backendHelper";
import  NotaryGlba  from 'model/notaryGlba';
import { showError } from "helpers/utilHelper";
import { useProfile } from "context/profile";

const ViewGlbaAssessment = () => {

  // router hook that helps redirect
  const navigate = useNavigate();

  const { notary } = useProfile();

  /********** STATE **********/
  const [glba, setGlba] = useState({});

  /********** OTHER **********/
  const convertYesNo = value =>  value ? 'Yes' : 'No';

  const onGetGlba = () => {
    getNotaryGlba(notary?.glba?.id)
      .then(resp => setGlba(resp.notaryGlba))
      .catch((err) => {
        showError("Unable to load Glba Assessment data");
      })
  }

  /********** EFFECTS **********/
  // runs once on component mount
  useEffect(() => {
    onGetGlba();
  }, []);

  return <Card className="glba-assessment-card expand-v p-0">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>GLBA Assessment Form</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            <Button type="button" color="primary" className="mb-2" onClick={()=> navigate(route(routes.glba_general_information))}>
              <i className="mdi mdi-pencil me-1" />Edit
            </Button>
          </div>
        </Col>
      </Row>
    </CardHeader>
    <CardBody className="pt-0">
      <div className="glba-assessment-wrapper">
        <Row>
          <Col className="right-border" lg="4">
            <div className="column-title">General Information</div>

            <div className="column-question">Confirm that you are an authorized representative of the Service Provider with sufficient knowledge to complete this assessment.</div>
            <div className="column-answer">{(glba.authorizedRepresentative && !glba.isNotaryAuthorizedRepresentative) ? glba.authorizedRepresentative : convertYesNo(glba.isNotaryAuthorizedRepresentative) || '--'}</div>

            <div className="column-question">Does Service Provider perform security awareness training?</div>
            <div className="column-answer">{convertYesNo(glba.isSecurityTrainingPerformed) || '--'}</div>

            <div className="column-question">Does Service Provider perform phishing simulations?</div>
            <div className="column-answer">{convertYesNo(glba.arePhishingSimulationsPerformed) || '--'}</div>

            <div className="column-question">How many employees does Service Provider employ?</div>
            <div className="column-answer">{(glba.otherEmployees && glba.noOfEmployees === NotaryGlba.EMPLOYEES_SELF_AND_OTHERS) ? `Self and ${glba.otherEmployees}`: 'Self' || '--'}</div>

            <div className="column-question">How frequently does Service Provider complete security awareness training?</div>
            <div className="column-answer">{(glba.securityTrainingFrequencyDetails && glba.securityTrainingFrequency === NotaryGlba.SECURITY_TRAINING_OTHER) ? glba.securityTrainingFrequencyDetails : NotaryGlba.getSecurityFrequently(glba.securityTrainingFrequency) || '--'}</div>
          </Col>

          <Col className="right-border" lg="4">
            <div className="column-title">Security Practices</div>

            <div className="column-question">Does Service Provider maintain appropriate safeguards to protect customer information?</div>
            <div className="column-answer">{convertYesNo(glba.isCustomerInfoProtected) || '--'}</div>

            <div className="column-question">Does Service Provider host a website or application that stores customer information?</div>
            <div className="column-answer">{convertYesNo(glba.isCustomerInfoStored) || '--'}</div>

            <div className="column-question">Does Service Provider hire a subcontractor when handling customer information?(excluding computer services) from-and-to Mavsign or Dealership?</div>
            <div className="column-answer">{(glba.hiredSubcontractor && glba.isSubcontractorHired) ? glba.hiredSubcontractor : convertYesNo(glba.isSubcontractorHired) || '--'}</div>

            <div className="column-question">Are Service Provider’s operating systems, firewalls, and anti-virus software up-to-date?</div>
            <div className="column-answer">{convertYesNo(glba.areSystemsUpdated) || '--'}</div>

            <div className="column-question">How does Service Provider store customer information?</div>
            <div className="column-answer">
              {glba?.customerInfoStorageType?.length > 0 ? (
                <>
                  {glba.customerInfoStorageType.map((item, index) => (
                    <p className="mb-2" key={index}>
                      {NotaryGlba.getStorageTypeName(item)}
                    </p>
                  ))}
                  {glba.customerInfoStorageDetails &&
                    glba.customerInfoStorageType.includes(NotaryGlba.CUSTOMER_INFO_STORAGE_TYPE_OTHER) && (
                      <p className="mb-2">{glba.customerInfoStorageDetails}</p>
                    )}
                </>
              ) : <div>--</div>}
            </div>
          </Col>

          <Col className="pe-4" lg="4">
            <div className="column-title">Access and Retention Policies</div>

            <div className="column-question">Does Service Provider authenticate and permit access only to authorized users to access customer information?</div>
            <div className="column-answer">{convertYesNo(glba.hasRestrictedAccess) || '--'}</div>

            <div className="column-question">How long does Service Provider store customer information?</div>
            <div className="column-answer">{(glba.customerInfoStoragePeriodDetails && glba.customerInfoStoragePeriod === NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_OTHER) ?
              glba.customerInfoStoragePeriodDetails :
              NotaryGlba.getStoragePeriod(glba.customerInfoStoragePeriod) || '--'}
            </div>

            <div className="column-question">Has Service Provider experienced a data breach or security event in the past year?</div>
            <div className="column-answer">{convertYesNo(glba.hasExperiencedSecurityEvent) || '--'}</div>

            <div className="column-question">Does Service Provider dispose of, or destroy, customer information after its legitimate business or lawful purpose?</div>
            <div className="column-answer">{(() => {
              if (glba.customerInfoDisposalDetails && glba.customerInfoDisposal === NotaryGlba.CUSTOMER_INFO_DISPOSAL_OTHER) {
                return glba.customerInfoDisposalDetails;
              }

              if (glba.customerInfoDisposal === NotaryGlba.CUSTOMER_INFO_DISPOSAL_YES) {
                return "Yes";
              }

              if (glba.customerInfoDisposal === NotaryGlba.CUSTOMER_INFO_DISPOSAL_NO) {
                return "No";
              }

              return "--";
            })()}
            </div>

            <div className="column-question">If Service Provider is a state commissioned notary, how long does the state require Service Provider to hold onto notarized customer information?</div>
            <div className="column-answer">{glba.customerInfoStoragePeriodRequirement || '--'}</div>
          </Col>
        </Row>
      </div>
    </CardBody>
  </Card>
}

export default ViewGlbaAssessment;