import React from "react";
import { Card, CardBody, CardTitle, Col, Row, List } from "reactstrap";
import { getBeUrl } from "../../../helpers/utilHelper";
import downloadFileIcon from "assets/images/download-file-icon.svg";
import termsAgreedIcon from "assets/images/terms-agreed-icon.svg";
import glbaCompletedIcon from "assets/images/glba-completed-icon.svg";
import notaryProfileIcon from "assets/images/notary-profile-icon.svg";
import { route, routes } from "helpers/routeHelper";
import { Link } from "react-router-dom"

const ProfileCompletion = () => {
  return (
    <Card className="documents-card">
      <CardTitle>Profile Completion</CardTitle>
      <CardBody className="p-0">
        <List className="list-unstyled">
          <li className="font-size-13 mt-2 d-flex align-items-center">
            <img src={termsAgreedIcon} alt="terms" className="me-2" height={20} />
            Agree to the <Link to={route(routes.view_terms)} rel="noreferrer" className="ms-1">terms of service</Link>
          </li>
          <li className="font-size-13 mt-2 d-flex align-items-center">
            <img src={glbaCompletedIcon} alt="terms" className="me-2" height={20} />
            Complete your <Link to={route(routes.glba_general_information)} rel="noreferrer" className="ms-1">GLBA form</Link>
          </li>
          <li className="font-size-13 mt-2 d-flex align-items-center">
            <img src={notaryProfileIcon} alt="terms" className="me-2" height={20} />
            Complete your <Link to={route(routes.view_profile)} rel="noreferrer" className="ms-1">Notary profile</Link>
          </li>
        </List>
        <Row className="mt-5">
          <p className="font-size-16 fw-normal">Download Document Templates</p>
          <Col xs={4} md={6} xl={6} xxl={4} className="text-start">
            <a href={getBeUrl(`notary/std-doc/aoi.pdf`)} target="_blank" rel="noreferrer" className="btn btn-light mav-card-action-btn download-link download-template-button">
              <div className="d-flex flex-column justify-content-between align-items-center h-100">
                <img src={downloadFileIcon} className="mt-2 font-size-15" />
                <span>AOI</span>
              </div>
            </a>
          </Col>
          <Col xs={4} md={6} xl={6} xxl={4} className="text-start">
            <a href={getBeUrl(`notary/std-doc/aoi_no_fingerprint.pdf`)} target="_blank" rel="noreferrer" className="btn btn-light mav-card-action-btn download-link download-template-button">
              <div className="d-flex flex-column justify-content-between align-items-center h-100">
                <img src={downloadFileIcon} className="mt-2 font-size-15" />
                <span>AOI - No Fingerprint</span>
              </div>
            </a>
          </Col>
          <Col xs={4} md={12} xl={12} xxl={4} className="text-start">
            <a href={getBeUrl(`notary/std-doc/notary-checklist.pdf`)} target="_blank" rel="noreferrer" className="btn btn-light mav-card-action-btn download-link download-template-button">
              <div className="d-flex flex-column justify-content-between align-items-center h-100">
                <img src={downloadFileIcon} className="mt-2 font-size-15" />
                <span>Notary Checklist</span>
              </div>
            </a>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default ProfileCompletion;